<template>
  <div class="hero-section">
    <img class="sub-image-1" src="../../assets/imgs/yellow_left.png" alt="" />
    <img class="sub-image-2" src="../../assets/imgs/yellow_right.png" alt="" />
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="d-flex flex-column position-relative centered hero-section__main-col"
        >
          <div class="position-relative div">
            <p class="hero-section__title">
              BESPAREN OP <br />

              <span class="hero-section__white"> EEN LAADPAAL? </span>
            </p>

            <p class="my-4 hero-section__text">
              Met onze unieke woningscan checken wij:
            </p>

            <div
              class="d-flex align-items-sm-center align-items-baseline mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3"
                >Wat jouw opties zijn;</span
              >
            </div>
            <div
              class="d-flex align-items-sm-center align-items-baseline mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3"
                >De best beschikbare offertes voor jouw situatie;</span
              >
            </div>

            <div
              class="d-flex align-items-sm-center align-items-baseline mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3"
                >De juiste vakmannen voor gratis vrijblijvend en persoonlijk
                advies;</span
              >
            </div>
            <div
              class="d-flex align-items-sm-center align-items-baseline mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3"
                >Ontvang 4 gratis offertes van lokale en gecertificeerde
                partners</span
              >
            </div>

            <p class="mt-3 mb-sm-0 mb-3 hero-section__text">
              Vergelijk en bespaar tot 40% op de aanschafprijs!
            </p>

            <div class="d-flex align-items-center">
              <div>
                <b-button
                  class="hero-section__btn position-relative"
                  @click="$emit('nextPage')"
                >
                  START DE GRATIS woningSCAN!

                  <p class="hero-section__sub-text position-absolute">
                    Klaar binnen 1 minuut
                  </p>
                </b-button>
              </div>
              <div
                class="hero-section__div ml-4 d-flex justify-content-center align-items-center"
              >
                <span class=""
                  >Eigen<br />
                  stroom is <br />
                  goedkoper!</span
                >
              </div>
            </div>

            <img
              src="@/assets/imgs/arrow.png"
              class="position-absolute arrow"
              alt="My Image"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import backgroundImage from "../assets/imgs/herosection.png";

export default {
  name: "HeroSection",
};
</script>

<style lang="scss" scoped>
.centered {
  @media (min-width: 1198.99px) and (max-width: 3499.99px) {
    position: absolute;
    top: 50%;
    left: 25%;
    bottom: 130px;
    transform: translate(-50%, -71%);
  }
  @media (min-width: 625.99px) and (max-width: 1198.99px) {
    left: 35%;
    transform: translate(-50%, -50%);
    top: 50%;
    bottom: 130px;
  }
}
.sub-image-2 {
  height: 15vw;
  position: absolute;
  right: 0;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.sub-image-1 {
  height: 70vh;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.hero-section {
  background-image: url("../../assets/imgs/laadpalen1.jpg");
  min-height: 100px !important;
  // background-size: 100% 100% !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 70vh;
  // padding-top: 70px;
  // padding-bottom: 70px;
  overflow: hidden;
  @media (max-width: 1198.99px) {
    height: 86vh;
  }
  @media (max-width: 625.99px) {
    background-image: url("../../assets/imgs/laadpalen.png");
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
  }
  &__title {
    // font-size: 64px;
    font-size: 40px;
    // line-height: 58px;
    line-height: 38px;
    text-transform: uppercase;
    color: white !important;
    font-weight: 800;
  }
  &__white {
    color: #ffcc01 !important;
  }

  &__text {
    // font-size: 22px;
    // line-height: 36px;
    font-size: 15px;

    color: #ffffff;
    font-weight: 700;
  }

  &__sub-text {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
    color: #ffffff;
    font-weight: 400;
    right: 20px;
    top: 60px;
  }
  &__div {
    width: 130px !important;
    height: 130px !important;
    border-radius: 50%;
    background-color: #1d1729;
    color: #ffcc01;

    font-weight: 800;
    font-size: 13px;
    text-align: center;
    @media (max-width: 1024.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
    }
    @media (max-width: 991.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
    }
    @media (max-width: 575.99px) {
      width: 100px !important;
      height: 100px !important;
      font-size: 12px !important;
      margin-top: 5px !important;
    }
  }
  &__btn {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    background: #ffcc01;
    margin-top: 20px;
    border-radius: 30px;
    padding: 15px 30px;
    border: none !important;
    &:hover,
    &:active {
      background-color: #ffcc01;
      color: #1d1729;
    }
  }

  .arrow {
    left: -95px !important;
    bottom: 15%;
  }

  &__main-col {
    margin-left: 50% !important;
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
    }
    &__div {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 1199.99px) {
    &__main-col {
      margin-left: 42% !important;
    }
  }
  @media (max-width: 991.99px) {
    &__main-col {
      margin-left: 8% !important;
    }
    &__title {
      font-size: 35px;
    }

    &__text {
      font-size: 14px;
    }
    &__sub-text {
      font-size: 12px;
      top: 50px;
    }
    &__btn {
      font-size: 12px;
      padding: 10px 20px;
    }
    .arrow {
      left: -40px !important;
      bottom: 11%;
      width: 40px !important;
    }
  }

  @media (max-width: 767.99px) {
    // padding-top: 30px;
    // padding-bottom: 30px;
    &__main-col {
      margin-left: 10% !important;
    }
    &__title {
      font-size: 25px;
      line-height: 30px !important;
    }
    &__div span {
      font-size: 12px;
    }

    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 10px;
      top: 40px;
    }
    &__btn {
      font-size: 10px;
      padding: 8px 16px;
      letter-spacing: 0.2px;
    }
    .arrow {
      // display: none;
    }
    .check-img {
      width: 15px !important;
    }
  }

  @media (max-width: 625.99px) {
    .check-img {
      width: 12px !important;
    }
    &__main-col {
      margin-left: 14% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 25px !important;
    }

    &__text {
      font-size: 13px;
    }
    &__sub-text {
      font-size: 9px;
      top: 30px;
    }
    &__btn {
      font-size: 9px;
      padding: 7px 14px;
    }
  }

  @media (max-width: 575.99px) {
    padding-top: 20px;
    padding-bottom: 20px;
    .check-img {
      width: 10px !important;
    }
    &__main-col {
      margin-left: 16% !important;
      .div {
        margin-right: 4rem;
      }
    }
    &__title {
      font-size: 25px;
      // line-height: 18px !important;
      margin-bottom: 5px !important;
    }

    .arrow {
      // display: none;

      rotate: 35deg;
      width: 60px;
    }
    &__text {
      // font-size: 13px;
    }
    &__sub-text {
      font-size: 10px;
      top: 30px;
      right: 0px;
    }
    &__btn {
      font-size: 10px;
      padding: 7px 14px;
      margin-top: 10px;
    }
    &__div span {
      font-size: 12px;
    }
    &__div {
      width: 100px;
      height: 100px;
      position: absolute;
      background-color: black;
      top: -30px;
      left: 195px;
      @media (max-width: 399.99px) {
        top: -60px;
        left: 180px;
      }
    }
  }

  @media (max-width: 374.99px) {
    &__main-col {
      margin-left: 13.5% !important;
      .div {
        margin-right: 2rem;
      }
    }
    &__title {
      // font-size: 13px;
      // line-height: 15px !important;
    }

    &__text {
      // font-size: 7px;
    }
    &__sub-text {
      font-size: 9px;
      top: 27px;
    }
    &__btn {
      font-size: 11px;
      padding: 6px 12px;
    }
    &__div {
      left: 175px;
      top: -60px;
    }
  }
  @media (max-width: 319.99px) {
    &__main-col {
      margin-left: 13.5% !important;
    }
    &__title {
      font-size: 20px;
    }
    &__text {
      font-size: 11px;
    }
    &__div {
      left: 135px;
      top: -40px;
    }
  }
}
</style>
