<template>
  <div class="postcode">
    <b-container>
      <b-form @submit="handleNextPage">
        <b-row>
          <b-col cols="12" md="7" class="d-flex align-items-start">
            <img
              src="@/assets/imgs/arrow-right.png"
              class="img-fluid"
              alt="arrow"
            />

            <span class="ml-md-5 ml-4 postcode__text">
              Start onze unieke woningscan!
            </span>
          </b-col>
          <b-col cols="12" md="5" class="mt-md-0 mt-3">
            <div class="row no-gutters">
              <div class="col-8">
                <b-form-input
                  v-model="postdata.zip"
                  @input="handleFieldInput('zip')"
                  placeholder="Postcode"
                  :state="postdata.zip ? zipValidation : null"
                  required
                ></b-form-input>
                <b-form-invalid-feedback v-if="!zipValidation">
                  Voer een geldige postcode in
                </b-form-invalid-feedback>
              </div>
              <div class="col-4">
                <b-form-input
                  v-model="postdata.house_number"
                  @input="handleFieldInput('house_number')"
                  placeholder="Huisnr."
                  class="ml-2"
                  :state="postdata.house_number ? houseNumberValidation : null"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!houseNumberValidation">
                  Voer een geldige huisnr in
                </b-form-invalid-feedback>
              </div>
            </div>

            <div class="my-3" v-if="setAddress">
              <b-alert show dismissible class="alert-danger">
                Combinatie komt niet overeen!
              </b-alert>
            </div>
            <b-button class="mt-3 w-100" type="submit">
              Start woningSCAN</b-button
            >

            <!-- <div class="d-flex">
            <b-form-input v-model="text" placeholder="Postcode"></b-form-input>
            <b-form-input
              v-model="text"
              placeholder="Huisnr."
              class="ml-2"
            ></b-form-input>
          </div> -->
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PostCodeForm",
  data() {
    return {
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      houseRegex: /^[0-9A-Za-z\s\-]+$/,
      setAddress: false,
    };
  },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    handleNextPage(e) {
      e.preventDefault();

      if (this.isValidForm) {
        const houseNumberWithoutPunctuation =
          this.postdata.house_number.replace(/[\s-]/g, "");
        this.getAddress({
          zip: this.postdata.zip,
          house_number: houseNumberWithoutPunctuation,
          onSuccess: () => {
            this.$emit("nextPage", "post");
            this.setAddress = false;
          },
          onError: () => {
            this.setAddress = true;
            setTimeout(() => {
              this.setAddress = false;
            }, 2000);
          },
        });
      }
    },
  },

  computed: {
    ...mapGetters(["getErrorResponses"]),
    zipValidation() {
      if (this.postdata.zip === "" && !this.getErrorResponses.zip) {
        return null;
      }
      if (this.getErrorResponses.zip == false) {
        return false;
      } else if (
        this.zipRegex.test(this.postdata.zip) ||
        this.postdata.zip === ""
      ) {
        return true;
      }
      return this.zipRegex.test(this.postdata.zip) || this.postdata.zip === "";
    },
    houseNumberValidation() {
      if (
        this.postdata.house_number === "" &&
        !this.getErrorResponses.house_number
      ) {
        return null;
      }
      if (this.getErrorResponses.house_number == false) {
        return false;
      } else if (
        this.houseRegex.test(this.postdata.house_number) ||
        this.postdata.house_number === ""
      ) {
        return true;
      }
      return (
        this.houseRegex.test(this.postdata.house_number) ||
        this.postdata.house_number === ""
      );
    },
    isValidForm() {
      return this.zipValidation && this.houseNumberValidation;
    },
  },
};
</script>

<style lang="scss" scoped>
.postcode {
  background: #ffe786;
  padding-top: 70px;
  padding-bottom: 70px;

  &__text {
    font-size: 36px;
    line-height: 40px;
    color: #1d1729;
    font-weight: 800;
  }

  .form-control {
    background-color: #ffffff;
    border: 1px solid #cccbcb;
    border-radius: 0px !important;
    color: #495057 !important;
  }

  .btn {
    border-radius: 37px;
    background-color: #1d1729;
    padding: 10px;
    // font-size: 17px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fefefe;
    font-weight: 900;
    border: none !important;

    &:hover,
    :active {
      background-color: #1d1729;
      color: #fefefe;
    }
  }

  @media (max-width: 1023.99px) {
    &__text {
      font-size: 25px;
      line-height: 30px;
    }
    .btn {
      font-size: 13px;
    }
    img {
      width: 30px;
    }
  }

  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 575.99px) {
    &__text {
      font-size: 20px;
      line-height: 25px;
    }
    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }
    img {
      width: 25px;
    }
  }
}
</style>
