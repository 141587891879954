<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />

        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">Wat is jouw adres?</p>

        <b-form @submit="handleNextQuestion">
          <div class="row no-gutters first-question">
            <div class="col-lg-10 col-12">
              <div class="row no-gutters">
                <div class="col-7 pr-1">
                  <b-form-input
                    v-model="details.zip"
                    placeholder="Postcode"
                    required
                    :state="details.zip ? zipValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!zipValidation">
                    Voer een geldige postcode in
                  </b-form-invalid-feedback>
                </div>
                <div class="col-5 pl-1">
                  <b-form-input
                    v-model="details.house_number"
                    placeholder="Huisnr."
                    required
                    :state="details.house_number ? houseNumberValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!houseNumberValidation">
                    Voer een geldige huisnr in
                  </b-form-invalid-feedback>
                </div>

                <div
                  class="mt-3 auto-complete"
                  v-if="!setAddress && details.house_number && address"
                >
                  <div
                    v-if="loader"
                    class="spinner-border text-info fs--10"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span v-else
                    >{{ address.street }}
                    <span>{{ this.details.house_number }},</span>
                    {{ address.municipality }}
                  </span>
                </div>
              </div>
              <div class="my-3" v-if="setAddress">
                <b-alert show dismissible class="alert-danger">
                  Combinatie komt niet overeen !
                </b-alert>
              </div>
              <b-button class="mt-3 w-100" type="submit"> Volgende </b-button>
            </div>
          </div>
        </b-form>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col"> </b-col>
    </b-row>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "First_Question",
  data() {
    return {
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      houseRegex: /^[0-9A-Za-z\s\-]+$/,
      streetRegex: /^[a-zA-Z ]*$/,
      selected: null,
      options: [],
      selectedStreet: "",
      invalidStreet: false,
      streetItems: [],
      setAddress: false,
    };
  },
  components: { Multiselect },

  props: {
    details: {
      type: Object,
      required: true,
    },

    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleNextQuestion(e) {
      e.preventDefault();

      if (this.isValidForm) {
        const houseNumberWithoutPunctuation = this.details.house_number.replace(
          /[\s-]/g,
          ""
        );
        this.getStreets({
          zip: this.details.zip,
          house_number: houseNumberWithoutPunctuation,
          onSuccess: () => {
            this.$emit("nextQuestion", 2);
            this.setAddress = false;
          },
          onError: () => {
            this.setAddress = true;
            setTimeout(() => {
              this.setAddress = false;
            }, 2000);
          },
        });
      }
    },
    goToPreviousPage() {
      this.$emit("previousPage");
    },
  },
  computed: {
    zipValidation() {
      return this.zipRegex.test(this.details.zip) || this.details.zip === "";
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.details.house_number) ||
        this.details.house_number === ""
      );
    },
    streetValidation() {
      return (
        this.streetRegex.test(this.details.street) || this.details.street === ""
      );
    },
    cityValidation() {
      return (
        this.streetRegex.test(this.details.city) || this.details.city === ""
      );
    },
    isValidForm() {
      return (
        this.zipValidation && this.houseNumberValidation
        // this.streetValidation
      );
    },
  },
  watch: {
    "details.house_number"(newVal, oldVal) {
      this.loader = true;
      if (this.isValidForm) {
        const houseNumberWithoutPunctuation = this.details.house_number.replace(
          /[\s-]/g,
          ""
        );
        this.getStreets({
          zip: this.details.zip,
          house_number: houseNumberWithoutPunctuation,
          onSuccess: () => {
            this.setAddress = false;
            this.loader = false;
          },
          onError: () => {
            this.setAddress = true;
            this.loader = false;
            // setTimeout(() => {
            //   this.setAddress = false;
            //  }, 3000);
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/isolatie-new.jpg);
    background-size: 100% 100% !important;
  }
}

.street-dropdown {
  color: #bbbabc !important;
}

.auto-complete {
  width: 100% !important;
}

.invalid-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
