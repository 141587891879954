<template>
  <div class="hero-section">
    <!-- :style="{
      'background-image':
        'url(' + require('../assets/imgs/herosection.png') + ')',
    }" -->
    <img class="sub-image-1" src="../../assets/imgs/yellow_left.png" alt="" />
    <img class="sub-image-2" src="../../assets/imgs/yellow_right.png" alt="" />
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="d-flex flex-column position-relative centered hero-section__main-col"
        >
          <p class="hero-section__title">
            <!-- Geld besparen èn geld <br />
            verdienen -->
            <span class="hero-section__white">
              <!-- met <br />
              zonnepanelen? -->
              ZONNEPANELEN HEBBEN MEER VOORDELEN DAN OOIT
            </span>
          </p>
          <p class="my-md-3 my-2 hero-section__secondaryTitle">
            Geniet nu van de laagste prijs ooit voor zonnepanelen, 21% <br />
            belastingkorting én de salderingsregeling.
          </p>
          <p class="my-md-3 my-2 hero-section__text">
            Met onze unieke dakscan checken wij:
          </p>

          <div class="d-flex align-items-center mt-1 mt-md-2">
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Of jouw dak geschikt is;</span
            >
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Of jij recht hebt op 21% belastingkorting;</span
            >
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Schatting van jouw besparing met zonnepanelen;</span
            >
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Beschikbare installateurs.</span
            >
          </div>
          <div class="d-flex align-items-center mt-2 mt-md-2">
            <!-- <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            /> -->
            <span class="hero-section__text d-none d-md-flex"
              >Ontvang nu 4 gratis offertes van lokale en gecertificeerde
              partners
            </span>
            <span class="hero-section__text my-2 d-md-none d-flex"
              >Ontvang nu 4 gratis offertes van lokale en gecertificeerde
              partners
            </span>
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <span class="hero-section__text my-1"
              >Vergelijk en bespaar tot wel 40% op de aanschafprijs.
            </span>
          </div>

          <div class="d-flex align-items-center">
            <b-button
              class="hero-section__btn position-relative"
              @click="$emit('nextPage')"
            >
              Start Gratis Dakscan ‌
              <p class="hero-section__sub-text position-absolute">
                Klaar binnen 1 minuut
              </p>
            </b-button>
            <div
              class="hero-section__div ml-4 d-flex justify-content-center align-items-center"
            >
              <span class="">
                Salderings - <br />
                regeling <br />
                verlengt!</span
              >
            </div>
          </div>

          <img
            src="@/assets/imgs/arrow.png"
            class="position-absolute arrow"
            alt="My Image"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import backgroundImage from "../assets/imgs/herosection.png";

export default {
  name: "HeroSection",
};
</script>

<style lang="scss" scoped>
.centered {
  @media (max-width: 3499.99px) and (min-width: 1198.99px) {
    position: absolute;
    top: 50%;
    left: 41%;
    bottom: 50px;
    transform: translate(-50%, -60%);
  }
  @media (max-width: 3499.99px) and (min-width: 1597.99px) {
    position: absolute;
    top: 50%;
    left: 41%;
    bottom: 350px;
    transform: translate(-50%, -60%);
  }
  @media (min-width: 627px) and (max-width: 1198.99px) {
    top: 50%;
    left: 41%;
    bottom: 70px;
    transform: translate(-50%, -50%);
  }
}
.sub-image-2 {
  height: 15vw;
  position: absolute;
  right: 0;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.sub-image-1 {
  height: 70vh;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.hero-section {
  // min-height: 100px !important;
  //background-size: 100% 100% !important;
  // background-size: cover;
  // background-position-x: right;
  background-image: url("../../assets/imgs/headersolar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  height: 105vh;
  // position: relative;
  // padding-top: 70px;
  // padding-bottom: 70px;

  overflow: hidden;
  @media (max-width: 3499.99px) and (min-width: 1198.99px) {
    height: 100vh;
  }
  @media (max-width: 3499.99px) and (min-width: 1598.99px) {
    height: 70vh;
  }

  @media (max-width: 625.99px) {
    background-image: url("../../assets/imgs/header_image.png");
    height: 100dvh;
  }
  &__title {
    // font-size: 64px;
    font-size: 50px;
    // line-height: 58px;
    line-height: 50px;
    text-transform: uppercase;
    color: #ffcc01;
    font-weight: 800;
  }
  &__white {
    color: #ffffff !important;
  }
  &__secondaryTitle {
    font-size: 21px;

    color: #ffffff;
    font-weight: 700;
  }
  &__text {
    // font-size: 22px;
    // line-height: 36px;
    font-size: 16px;

    color: #ffffff;
    font-weight: 700;
  }

  &__sub-text {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
    color: #ffffff;
    font-weight: 400;
    right: 20px;
    top: 60px;
  }
  &__div {
    width: 130px !important;
    height: 130px !important;
    border-radius: 50%;
    background-color: #1d1729;
    color: #ffcc01;

    font-weight: 800;
    font-size: 14px;
    text-align: center;
    @media (max-width: 1024.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
    }
    @media (max-width: 991.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
    }
    @media (max-width: 575.99px) {
      width: 100px !important;
      height: 100px !important;
      font-size: 12px !important;
      margin-top: 5px !important;
    }
  }
  &__btn {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    background: #ffcc01;
    margin-top: 20px;
    border-radius: 30px;
    padding: 15px 30px;
    border: none !important;
    &:hover,
    &:active {
      background-color: #ffcc01;
      color: #1d1729;
    }
  }

  .arrow {
    left: -80px !important;
    bottom: 10%;
  }

  &__main-col {
    margin-left: 10% !important;
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
    }
    &__div {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 991.99px) {
    &__main-col {
      margin-left: 5% !important;
    }
    &__title {
      font-size: 35px;
    }
    &__secondaryTitle {
      font-size: 23px;
    }
    &__text {
      font-size: 14px;
    }
    &__sub-text {
      font-size: 12px;
      top: 50px;
    }
    &__btn {
      font-size: 12px;
      padding: 10px 20px;
    }
    .arrow {
      left: -40px !important;
      bottom: 5%;
      width: 40px !important;
    }
  }

  @media (max-width: 767.99px) {
    &__main-col {
      margin-left: 100px !important;
    }
    &__title {
      font-size: 25px;
      line-height: 30px !important;
    }
    &__div span {
      font-size: 12px;
    }
    &__secondaryTitle {
      font-size: 20px;
    }
    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 10px;
      top: 40px;
    }
    &__btn {
      font-size: 10px;
      padding: 8px 16px;
      letter-spacing: 0.2px;
    }
    .arrow {
      display: none;
    }
    .check-img {
      width: 15px !important;
    }
  }

  @media (max-width: 625.99px) {
    padding-top: 60px;
    padding-bottom: 30px;
    .check-img {
      width: 12px !important;
    }
    &__main-col {
      margin-left: 16% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 25px !important;
    }
    &__secondaryTitle {
      font-size: 15px;
    }
    &__text {
      font-size: 10px;
    }
    &__sub-text {
      font-size: 8px;
      top: 30px;
    }
    &__btn {
      font-size: 8px;
      padding: 7px 14px;
    }
  }

  @media (max-width: 575.99px) {
    padding-top: 150px;
    padding-bottom: 35px;
    .check-img {
      width: 10px !important;
    }
    &__main-col {
      margin-left: 14% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 18px !important;
      margin-bottom: 5px !important;
    }
    &__div {
      width: 100px;
      height: 100px;
      position: absolute;
      background-color: black;
      top: -123px;
      left: 205px;
      @media (max-width: 399.99px) {
        top: -123px;
        left: 180px;
      }
    }
    &__secondaryTitle {
      font-size: 12px;
    }
    &__text {
      font-size: 13px;
    }
    &__sub-text {
      font-size: 10px;
      top: 38px;
    }
    &__btn {
      font-size: 15px;
      padding: 7px 14px;
      margin-top: 10px;
    }
  }

  @media (max-width: 374.99px) {
    padding-top: 142px;
    padding-bottom: 35px;
    &__main-col {
      margin-left: 12.5% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 20px !important;
    }
    &__div {
      left: 170px;
      top: -127px;
    }
    &__secondaryTitle {
      font-size: 12px;
    }
    &__text {
      font-size: 13px;
    }
    &__sub-text {
      font-size: 10px;
      top: 30px;
    }
    &__btn {
      font-size: 13px;
      padding: 6px 12px;
    }
  }
  @media (max-width: 349.99px) {
    &__secondaryTitle {
      font-size: 11px;
    }
    &__text {
      font-size: 11px;
    }
    &__div {
      left: 140px;
      top: -60px;
    }
  }
}
</style>
