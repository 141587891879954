<template>
  <div class="hero-section">
    <img class="sub-image-1" src="../assets/imgs/yellow_left.png" alt="" />
    <img class="sub-image-2" src="../assets/imgs/yellow_right.png" alt="" />
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="d-flex flex-column centered hero-section__main-col"
        >
          <div class="position-relative div">
            <p class="hero-section__title">
              BESPAREN OP <br />

              <span class="hero-section__white">
                KUNSTSTOF <br class="d-sm-none d-block" />KOZIJNEN?
              </span>
            </p>

            <p
              class="my-md-3 my-3 pb-1 pt-3 pt-md-0 pb-md-0 hero-section__text"
            >
              Onze unieke woningscan:
            </p>

            <div
              class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3">
                checkt de beste beschikbare offertes voor jouw woning;</span
              >
            </div>
            <!-- <div class="d-flex align-items-center mt-1 mt-md-2">
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3"
                >Of je recht hebt op subsidie;</span
              >
            </div> -->

            <div
              class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3"
                >controleert subsidie opties;</span
              >
            </div>
            <div
              class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3">
                zorgt voor gratis en vrijblijvend advies op maat;</span
              >
            </div>
            <div
              class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3">
                verzorgt vier gratis offertes van lokale en gecertificeerde
                partners!</span
              >
            </div>
            <!-- <br class="d-flex d-md-none" />
            <br class="d-flex d-md-none" /> -->
            <div class="d-flex align-items-center pt-2">
              <div>
                <b-button
                  class="hero-section__btn mt-4 mt-md-0 position-relative"
                  @click="$emit('nextPage')"
                >
                  START DE GRATIS woningSCAN!

                  <p class="hero-section__sub-text position-absolute">
                    Klaar binnen 1 minuut
                  </p>
                </b-button>
              </div>
              <div
                class="hero-section__div ml-4 d-flex justify-content-center align-items-center"
              >
                <span class=""
                  >Met in 2024 <br />
                  15% overheids- <br />
                  subsidie</span
                >
              </div>
            </div>

            <img
              src="@/assets/imgs/arrow.png"
              class="position-absolute arrow"
              alt="My Image"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import backgroundImage from "../assets/imgs/herosection.png";

export default {
  name: "HeroSection",
};
</script>

<style lang="scss" scoped>
.centered {
  @media (min-width: 991.99px) and (max-width: 3499.99px) {
    position: absolute;
    top: 45%;
    left: 25%;
    bottom: 130px;
    transform: translate(-50%, -50%);
  }
  @media (min-width: 625.99px) and (max-width: 991.99px) {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    bottom: 130px;
  }
}
.sub-image-2 {
  height: 15vw;
  position: absolute;
  right: 0;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.sub-image-1 {
  height: 70vh;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.hero-section {
  background-image: url("../assets/imgs/kunststod\ kozijnen1.jpg");
  // min-height: 100px !important;
  // background-size: 100% 100%;
  // background-size: cover;
  // padding-top: 70px;
  // padding-bottom: 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 75vh;
  overflow: hidden;
  @media (max-width: 1198.99px) {
    height: 86vh;
  }
  @media (max-width: 625.99px) {
    background-image: url("../assets/imgs/21341234sm.png");
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
  }
  &__title {
    // font-size: 64px;
    font-size: 40px;
    // line-height: 58px;
    line-height: 38px;
    text-transform: uppercase;
    color: white !important;
    font-weight: 800;
  }
  &__white {
    color: #ffcc01 !important;
  }

  &__text {
    // font-size: 22px;
    // line-height: 36px;
    font-size: 17px;

    color: #ffffff;
    font-weight: 700;
  }

  &__sub-text {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
    color: #ffffff;
    font-weight: 400;
    right: 10px;
    top: 60px;
  }
  &__div {
    width: 130px !important;
    height: 130px !important;
    border-radius: 50%;
    background-color: #1d1729;
    color: #ffcc01;

    font-weight: 800;
    font-size: 13px;
    text-align: center;
    @media (max-width: 1024.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
    }
    @media (max-width: 991.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
    }
    @media (max-width: 575.99px) {
      width: 100px !important;
      height: 100px !important;
      font-size: 12px !important;
      margin-top: 5px !important;
    }
  }
  &__btn {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    background: #ffcc01;
    margin-top: 20px;
    border-radius: 30px;
    padding: 15px 30px;
    border: none !important;
    &:hover,
    &:active {
      background-color: #ffcc01;
      color: #1d1729;
    }
  }

  .arrow {
    left: -90px !important;
    bottom: 15%;
  }

  &__main-col {
    margin-left: 40% !important;
    padding-left: 10%;
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
    }
    &__div {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 1199.99px) {
    &__main-col {
      margin-left: 41% !important;
    }
  }
  @media (max-width: 991.99px) {
    &__main-col {
      margin-left: 8% !important;
    }
    &__title {
      font-size: 35px;
    }

    &__text {
      font-size: 15px;
    }
    &__sub-text {
      font-size: 12px;
      top: 55px;
    }
    &__btn {
      font-size: 12px;
      padding: 10px 20px;
    }
    .arrow {
      left: -42px !important;
      bottom: 16%;
      width: 40px !important;
    }
  }

  @media (max-width: 767.99px) {
    // padding-top: 30px;
    // padding-bottom: 30px;
    &__main-col {
      margin-left: 10% !important;
    }
    &__title {
      font-size: 25px;
      line-height: 30px !important;
    }
    &__div span {
      font-size: 12px;
    }

    &__text {
      font-size: 15px;
    }
    &__sub-text {
      font-size: 10px;
      top: 40px;
    }
    &__btn {
      font-size: 10px;
      padding: 8px 16px;
      letter-spacing: 0.2px;
    }
    .arrow {
      // display: none;
    }
    .check-img {
      width: 15px !important;
    }
  }

  @media (max-width: 625.99px) {
    .check-img {
      width: 12px !important;
    }
    &__main-col {
      margin-left: 15% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 25px !important;
    }

    &__text {
      font-size: 15px;
    }
    &__sub-text {
      font-size: 9px;
      top: 30px;
    }
    &__btn {
      font-size: 9px;
      padding: 7px 14px;
    }
  }

  @media (max-width: 575.99px) {
    padding-top: 70px;
    padding-bottom: 20px;
    .check-img {
      width: 10px !important;
    }
    &__main-col {
      margin-left: 16% !important;
      .div {
        margin-right: 4rem;
      }
    }
    &__title {
      font-size: 25px;
      // line-height: 18px !important;
      margin-bottom: 5px !important;
    }

    .arrow {
      // display: none;

      rotate: 35deg;
      width: 60px;
    }
    &__text {
      // font-size: 13px;
    }
    &__sub-text {
      font-size: 10px;
      top: 34px;
      right: 0px;
    }
    &__btn {
      font-size: 12px;
      padding: 7px 14px;
      margin-top: 10px;
    }
    &__div span {
      font-size: 10px;
    }
    &__div {
      width: 100px;
      height: 100px;
      position: absolute;
      background-color: black;

      top: -30px;
      left: 167px;
    }
  }

  @media (max-width: 374.99px) {
    &__main-col {
      margin-left: 12.5% !important;
      .div {
        margin-right: 2rem;
      }
    }
    &__title {
      // font-size: 13px;
      // line-height: 15px !important;
    }

    &__text {
      font-size: 13px;
    }
    &__sub-text {
      font-size: 9px;
      top: 34px;
    }
    &__btn {
      font-size: 12px;
      padding: 8px 25px;
    }
    &__div {
      left: 160px;
      top: -20px;
    }
  }
  @media (max-width: 319.99px) {
    padding-top: 67px;
    padding-bottom: 20px;

    &__title {
      font-size: 20px;
    }
    &__div {
      left: 130px;
      top: -55px;
    }
  }
}
</style>
