import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=7bf2a274&scoped=true"
import script from "./Form.vue?vue&type=script&lang=js"
export * from "./Form.vue?vue&type=script&lang=js"
import style0 from "./Form.vue?vue&type=style&index=0&id=7bf2a274&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bf2a274",
  null
  
)

export default component.exports