<template>
  <div class="analyze-card">
    <p class="analyze-card__top-text">
      <span class="text-green">Nederlandsadvies.nl</span> analyseert nu je
      gegevens..
    </p>
    <b-progress :max="100" :value="value">
      <b-progress-bar :value="value"></b-progress-bar>
    </b-progress>

    <p class="analyze-card__progress-text">
      {{ address.street }} {{ address.streetNumber }}{{ address.premise }},
      {{ address.settlement }}
    </p>

    <!-- <p
      class="analyze-card__progress-text"
      v-if="address.results && address.results.length"
    >
      {{ address?.results[0]?.formatted_address }}
    </p> -->

    <div
      class="d-flex align-items-baseline align-items-md-center mt-1 check-item"
      v-if="showCheckItem1"
    >
      <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
      <p class="m-0 analyze-card__check">
        Jouw woning komt<span class="text-green"> in aanmerking </span>
      </p>
    </div>
    <div
      class="d-flex mt-1 check-item align-items-baseline align-items-md-center"
      v-if="showCheckItem2"
    >
      <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
      <p class="m-0 analyze-card__check">
        Met airco bespaar je tot
        <span class="text-green"> 65% op je gasrekening</span>
      </p>
    </div>
    <div
      class="d-flex mt-1 align-items-baseline align-items-md-center check-item"
      v-if="showCheckItem3"
    >
      <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
      <p class="m-0 analyze-card__check">
        Wij hebben

        <span class="text-green">beschikbare vakmensen</span>
      </p>
    </div>
    <div
      class="d-flex mt-1 check-item align-items-baseline align-items-md-center"
      v-if="showCheckItem4"
    >
      <img src="@/assets/imgs/check-success.png" class="mr-2" alt="check" />
      <p class="m-0 analyze-card__check">
        Jij bespaart tot <span class="text-green">40% op de aanschaf </span>
      </p>
    </div>

    <div class="bottom-item" v-if="showBottomItem">
      <p class="m-0 analyze-card__bottom-text mr-2">
        We sturen je nu door om je woningscan af te ronden....
      </p>
      <img src="@/assets/imgs/arrow-right.png" class="mr-2" alt="arrow" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalyzingSection",
  data() {
    return {
      value: 0,
      showCheckItem1: false,
      showCheckItem2: false,
      showCheckItem3: false,
      showCheckItem4: false,
      showBottomItem: false,
    };
  },
  methods: {
    handleNextQuestion() {
      this.$emit("nextQuestion", "form");
    },
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.value < 20) {
        this.value += 1;
      } else if (!this.showCheckItem1) {
        this.showCheckItem1 = true;
        this.value = 20;
      } else if (this.value < 40) {
        this.value += 1;
      } else if (!this.showCheckItem2) {
        this.showCheckItem2 = true;
        this.value = 40;
      } else if (this.value < 60) {
        this.value += 1;
      } else if (!this.showCheckItem3) {
        this.showCheckItem3 = true;
        this.value = 60;
      } else if (this.value < 80) {
        this.value += 1;
      } else if (!this.showCheckItem4) {
        this.showCheckItem4 = true;
        this.value = 80;
      } else if (this.value < 100) {
        this.value += 1;
      } else if (!this.showBottomItem) {
        this.showBottomItem = true;
        this.value = 100;
        clearInterval(interval);
        setTimeout(() => {
          // perform your function here
          this.$emit("nextQuestion", "form");
        }, 3000);
      }
    }, 60);
  },
};
</script>

<style lang="scss" scoped>
.analyze-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  padding: 50px;
  color: #1d1729;

  .progress {
    // height: 20px;
    height: 20px;
    border-radius: 14px;
    background-color: #ffffff;
    border: 1px solid #cccbcb;
  }

  .progress-bar {
    height: 20px;
    border-radius: 14px;
    background-color: #5cba01;
  }

  .text-green {
    color: #5cba01 !important;
  }

  &__top-text {
    // font-size: 45px;
    font-size: 35px;
    font-weight: 800;
  }

  &__progress-text {
    // font-size: 22px;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 40px;
  }

  &__check {
    font-size: 18px;
    font-weight: 700;
  }

  &__bottom-text {
    font-size: 24px;
    font-weight: 800;
  }

  .check-item {
    img {
      width: 20px;
      height: 20px;
    }
    display: flex;
    // align-items: center;
  }

  .bottom-item {
    display: flex;
    align-items: center;
    margin-top: 35px;
    img {
      width: 35px;
      height: 30px;
    }
  }

  @media (max-width: 991.99px) {
    padding: 40px;
    &__top-text {
      font-size: 30px;
    }

    &__progress-text {
      font-size: 16px;
      margin-bottom: 35px;
    }

    &__check {
      font-size: 16px;
    }

    &__bottom-text {
      font-size: 20px;
    }

    .bottom-item {
      margin-top: 30px;
      img {
        width: 30px;
        height: 25px;
      }
    }
  }

  @media (max-width: 767.99px) {
    padding: 30px;

    &__top-text {
      font-size: 25px;
    }

    &__progress-text {
      font-size: 14px;
      margin-bottom: 30px;
    }

    &__check {
      font-size: 14px;
    }

    &__bottom-text {
      font-size: 18px;
    }

    .bottom-item {
      margin-top: 25px;
      img {
        width: 25px;
        height: 20px;
      }
    }
  }

  @media (max-width: 575.99px) {
    padding: 30px 25px;
    .progress {
      height: 20px;
    }
    &__top-text {
      font-size: 22px;
    }

    &__progress-text {
      font-size: 12px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 12px;
    }

    &__bottom-text {
      font-size: 16px;
    }

    .bottom-item {
      margin-top: 20px;
      img {
        width: 23px;
        height: 18px;
      }
    }

    .check-item {
      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  @media (max-width: 481.99px) {
    padding: 25px 20px;
    &__top-text {
      font-size: 18px;
    }

    &__progress-text {
      font-size: 14px;
      margin-bottom: 25px;
    }

    &__check {
      font-size: 15px;
    }

    &__bottom-text {
      font-size: 14px;
    }

    .bottom-item {
    }
  }
}
</style>
