import { render, staticRenderFns } from "./Second_Question.vue?vue&type=template&id=d649fdf6&scoped=true"
import script from "./Second_Question.vue?vue&type=script&lang=js"
export * from "./Second_Question.vue?vue&type=script&lang=js"
import style0 from "./Second_Question.vue?vue&type=style&index=0&id=d649fdf6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d649fdf6",
  null
  
)

export default component.exports