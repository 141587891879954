<template>
  <!-- <b-form-group v-slot="{ ariaDescribedby }">
    <b-form-radio-group
      id="radio-group-2"
      v-model="selected"
      :aria-describedby="ariaDescribedby"
      name="radio-sub-component"
    >
      <div class="check-item mt-3" @click="handleClick('4238')">
        <b-form-radio value="4238"
          >Spouwmuur isoleren (isolatie in de muur)</b-form-radio
        >
      </div>
      <div class="check-item mt-3" @click="handleClick('4232')">
        <b-form-radio value="4232">Vloer isoleren</b-form-radio>
      </div>
      <div class="check-item mt-3" @click="handleClick('4235')">
        <b-form-radio value="4235">Dak isoleren</b-form-radio>
      </div>
    </b-form-radio-group>
  </b-form-group> -->
  <b-form-group v-slot="{ ariaDescribedby }">
    <b-form-checkbox-group
      id="checkbox-group-2"
      v-model="selected"
      :aria-describedby="ariaDescribedby"
      name="checkbox-sub-component"
    >
      <div class="check-item mt-3">
        <b-form-checkbox value="4238"
          >Spouwmuur isoleren (isolatie in de muur)</b-form-checkbox
        >
      </div>
      <div class="check-item mt-3">
        <b-form-checkbox value="4232">Vloer isoleren</b-form-checkbox>
      </div>
      <div class="check-item mt-3">
        <b-form-checkbox value="dak isoleren">Dak isoleren</b-form-checkbox>
      </div>
    </b-form-checkbox-group>
    <button
      @click="handleSubmit"
      :disabled="selected.length === 0"
      class="btn btn-warning btn-block mt-3"
    >
      Volgende
    </button>
  </b-form-group>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "FifthB_Question",
  props: {
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      value: "",
      houseRegex: /^[1-9]+$/,
      selected: [],
    };
  },
  components: {
    GoogleMapSection,
  },
  methods: {
    handleSubmit() {
      this.$emit("handleSelected", this.selected);
    },
    // handleClick(val) {
    //   console.log("val: ", val);
    //   // this.$emit("handleClick", val);
    //   // this.$emit("nextQuestion", "analyze", "4034");
    // },
    goToPreviousPage() {
      this.$emit("previousQuestion", "2");
    },
  },
  computed: {
    houseNumberValidation() {
      return this.houseRegex.test(this.value) || this.value === "";
    },
    isValidForm() {
      return this.houseNumberValidation;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
</style>
