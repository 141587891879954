import { render, staticRenderFns } from "./WorkCard.vue?vue&type=template&id=41fd2892&scoped=true"
import script from "./WorkCard.vue?vue&type=script&lang=js"
export * from "./WorkCard.vue?vue&type=script&lang=js"
import style0 from "./WorkCard.vue?vue&type=style&index=0&id=41fd2892&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41fd2892",
  null
  
)

export default component.exports