import LeadService from "@/services/LeadService.js";
import router from "@/router";
import axios from "axios";

const state = {
  getErrorResponse: {},
  leadLoading: false,
  leadResponse: {},
  zonnelResponse: {},
  // address: {},
  addressDetails: {},
  // allStreets: [],
  lastAnswers: null,
  address: {},
  location: {
    lat: 0,
    lng: 0,
  },
};

const getters = {
  leadLoading: (state) => state.leadLoading,
  leadResponse: (state) => state.leadResponse,
  zonnelResponse: (state) => state.zonnelResponse,
  address: (state) => state.address,
  location: (state) => state.location,
  addressDetails: (state) => state.addressDetails,
  lastAnswers: (state) => state.lastAnswers,
  getErrorResponses: (state) => state.getErrorResponse,
};

const actions = {
  async dakwerkenCreateLead({ commit, state }, payload) {
    // commit("setLeadLoading", true);

    //test
    // const testError = "Validation error for 'zip'";
    // const error = testError.match(/'([^']+)'/)[0].slice(1, -1);
    // commit("setError", { [error]: false });
    // commit("setLeadLoading", false);
    // return {
    //   success: false,

    // };
    if (
      payload?.interested != "" &&
      payload.interested == "Ik zoek enkel informatie"
    ) {
      router.push("/dakwerken-bedankt");
    } else {
      commit("setLeadLoading", true);

      let obj = payload.data;
      if (state.addressDetails?.settlement) {
        obj = { ...obj, city: state.addressDetails?.settlement };
      }
      // if (state.addressDetails?.settlement) {
      //   obj = { ...obj, city: state.addressDetails?.settlement };
      // }
      // if (state.addressDetails?.street) {
      //   obj = { ...obj, street: state.addressDetails?.street };
      // }

      try {
        if (payload.bootCheck == true) {
          commit("setLeadLoading", false);

        } else {
          let { data } = await LeadService.createLeadDakwerken(obj);
          commit("setLeadResponse", data);
          commit("setLeadLoading", false);
          router.push("/dakwerken-bedankt");
        }

      } catch (error) {
        // let { data } = error.response;
        commit("setLeadLoading", false);
        // if (data.error == "Conflict - duplicate") {
        //   router.push("/airconditioning-bedankt");
        // } else {
        //   alert(data.error);
        // }
        if (error.response && error.response.data.error === "Conflict - duplicate") {
          console.log("error=>", error.response.data.error)
          router.push("/dakwerken-bedankt");

        } else {
          const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
          commit("setError", { [errorKey]: false });
          console.error("Error Detail:", errorKey);
        }
      }
    }
  },
  async airconditioningCreateLead({ commit, state }, payload) {
    // commit("setLeadLoading", true);

    //test
    // const testError = "Validation error for 'zip'";
    // const error = testError.match(/'([^']+)'/)[0].slice(1, -1);
    // commit("setError", { [error]: false });
    // commit("setLeadLoading", false);
    // return {
    //   success: false,

    // };
    if (
      payload?.interested != "" &&
      payload.interested == "Ik zoek enkel informatie"
    ) {
      router.push("/bedankt");
    } else {
      commit("setLeadLoading", true);

      let obj = payload.data;
      if (state.addressDetails?.settlement) {
        obj = { ...obj, city: state.addressDetails?.settlement };
      }
      // if (state.addressDetails?.settlement) {
      //   obj = { ...obj, city: state.addressDetails?.settlement };
      // }
      // if (state.addressDetails?.street) {
      //   obj = { ...obj, street: state.addressDetails?.street };
      // }

      try {
        if (payload.bootCheck == true) {
          commit("setLeadLoading", false);
        } else {
          let { data } = await LeadService.createLeadAirconditioning(obj);
          commit("setLeadResponse", data);
          commit("setLeadLoading", false);
          router.push("/airconditioning-bedankt");
        }
      } catch (error) {
        // let { data } = error.response;
        commit("setLeadLoading", false);
        // if (data.error == "Conflict - duplicate") {
        //   router.push("/airconditioning-bedankt");
        // } else {
        //   alert(data.error);
        // }
        if (
          error.response &&
          error.response.data.error === "Conflict - duplicate"
        ) {
          console.log("error=>", error.response.data.error);
          router.push("/airconditioning-bedankt");
        } else {
          const errorKey = error.response.data.error
            .match(/'([^']+)'/)[0]
            .slice(1, -1);
          commit("setError", { [errorKey]: false });
          console.error("Error Detail:", errorKey);
        }
      }
    }
  },
  async createLead({ commit, state }, payload) {
    // commit("setLeadLoading", true);

    //test
    // const testError = "Validation error for 'zip'";
    // const error = testError.match(/'([^']+)'/)[0].slice(1, -1);
    // commit("setError", { [error]: false });
    // commit("setLeadLoading", false);
    // return {
    //   success: false,

    // };
    if (
      payload?.interested != "" &&
      payload.interested == "Ik zoek enkel informatie"
    ) {
      router.push("/bedankt");
    } else {
      commit("setLeadLoading", true);

      let obj = payload.data;
      if (state.addressDetails?.settlement) {
        obj = { ...obj, city: state.addressDetails?.settlement };
      }

      try {
        if (payload.bootCheck == true) {
          commit("setLeadLoading", false);
        } else {
          let { data } = await LeadService.createLead(obj);
          commit("setLeadResponse", data);
          commit("setLeadLoading", false);
          router.push("/bedankt");
        }
      } catch (error) {
        // let { data } = error.response;
        commit("setLeadLoading", false);
        // if (data.error == "Conflict - duplicate") {
        //   router.push("/bedankt");
        // } else {
        //   alert(data.error);
        // }
        if (
          error.response &&
          error.response.data.error === "Conflict - duplicate"
        ) {
          console.log("error=>", error.response.data.error);
          router.push("/bedankt");
        } else {
          const errorKey = error.response.data.error
            .match(/'([^']+)'/)[0]
            .slice(1, -1);
          commit("setError", { [errorKey]: false });
          console.error("Error Detail:", errorKey);
        }
      }
    }
  },
  async createIsolateLead({ commit, state }, payload) {
    if (
      payload?.interested != "" &&
      payload.interested == "Ik zoek enkel informatie"
    ) {
      router.push("/bedankt");
    } else {
      commit("setLeadLoading", true);
      let obj = payload.data;
      if (state.addressDetails?.settlement) {
        obj = { ...obj, city: state.addressDetails?.settlement };
      }

      try {
        if (payload.bootCheck == true) {
          commit("setLeadLoading", false);
        } else {
          let { data } = await LeadService.createIsolateLead(obj);
          commit("setLeadResponse", data);
          commit("setLeadLoading", false);
          router.push("/isolatie/bedankt");
        }
      } catch (error) {
        // let { data } = error.response;
        commit("setLeadLoading", false);
        // if (data.error == "Conflict - duplicate") {
        //   router.push("/isolatie/bedankt");
        // } else {
        //   alert(data.error);
        // }
        if (
          error.response &&
          error.response.data.error === "Conflict - duplicate"
        ) {
          console.log("error=>", error.response.data.error);
          router.push("/isolatie/bedankt");
        } else {
          const errorKey = error.response.data.error
            .match(/'([^']+)'/)[0]
            .slice(1, -1);
          commit("setError", { [errorKey]: false });
          console.error("Error Detail:", errorKey);
        }
      }
    }
  },
  async createLaadpalenLead({ commit, state }, payload) {
    // if (
    //   payload?.interested != "" &&
    //   payload.interested == "Ik zoek enkel informatie"
    // ) {
    //   router.push("/bedankt");
    // } else {
    commit("setLeadLoading", true);

    let obj = payload.data;
    if (state.addressDetails?.settlement) {
      obj = { ...obj, city: state.addressDetails?.settlement };
    }

    try {
      if (payload.bootCheck == true) {
        commit("setLeadLoading", false);
      } else {
        let { data } = await LeadService.createLaadpalenLead(obj);
        commit("setLeadResponse", data);

        if (obj.answers.includes("4573")) {
          let payload = { ...obj };
          let { data } = await LeadService.createZonnepanelenLead({
            ...payload,
            answers: ["4569"],
          });
          commit("setZonnelResponse", data);
        }

        commit("setLeadLoading", false);
        router.push("/laadpalen/bedankt");
      }
    } catch (error) {
      // let { data } = error.response;
      commit("setLeadLoading", false);
      // if (data.error == "Conflict - duplicate") {
      //   router.push("/laadpalen/bedankt");
      // } else {
      //   alert(data.error);
      // }
      if (
        error.response &&
        error.response.data.error === "Conflict - duplicate"
      ) {
        console.log("error=>", error.response.data.error);
        router.push("/laadpalen/bedankt");
      } else {
        const errorKey = error.response.data.error
          .match(/'([^']+)'/)[0]
          .slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
      }
    }

    // }
  },
  async createZonnepanelen({ commit, state }, payload) {
    if (
      payload?.interested != "" &&
      payload.interested == "Ik zoek enkel informatie"
    ) {
      router.push("/zonnepanelen/bedankt");
    } else {
      commit("setLeadLoading", true);
      let obj = payload.data;
      if (state.addressDetails?.settlement) {
        obj = { ...obj, city: state.addressDetails?.settlement };
      }

      try {
        if (payload.bootCheck == true) {
          commit("setLeadLoading", false);
        } else {
          let { data } = await LeadService.createZonnepanelen(obj);
          commit("setLeadResponse", data);
          commit("setLeadLoading", false);
          router.push("/zonnepanelen/bedankt");
        }
      } catch (error) {
        // let { data } = error.response;
        commit("setLeadLoading", false);
        // if (data.error == "Conflict - duplicate") {
        //   router.push("/isolatie/bedankt");
        // } else {
        //   alert(data.error);
        // }
        if (
          error.response &&
          error.response.data.error === "Conflict - duplicate"
        ) {
          console.log("error=>", error.response.data.error);
          router.push("/zonnepanelen/bedankt");
        } else {
          const errorKey = error.response.data.error
            .match(/'([^']+)'/)[0]
            .slice(1, -1);
          commit("setError", { [errorKey]: false });
          console.error("Error Detail:", errorKey);
        }
      }
    }
  },
  async createThuisbattarij({ commit, state }, payload) {
    if (
      payload?.interested != "" &&
      payload.interested == "Ik zoek enkel informatie"
    ) {
      router.push("/thuisbatterij-bedankt");
    } else {
      commit("setLeadLoading", true);
      let obj = payload.data;
      if (state.addressDetails?.settlement) {
        obj = { ...obj, city: state.addressDetails?.settlement };
      }

      try {
        if (payload.bootCheck == true) {
          commit("setLeadLoading", false);
        } else {
          console.log("payload.answers: ", payload.data.answers);
          commit("setLastAnswers", payload.data.answers);
          let { data } = await LeadService.createLeadThuisbattarij(obj);
          commit("setLeadResponse", data);
          commit("setLeadLoading", false);
          router.push("/thuisbatterij-bedankt");
        }
      } catch (error) {
        // let { data } = error.response;
        console.log("payload.answers: ", payload.data.answers);
        commit("setLastAnswers", payload.data.answers);
        commit("setLeadLoading", false);

        // if (data.error == "Conflict - duplicate") {
        //   router.push("/isolatie/bedankt");
        // } else {
        //   alert(data.error);
        // }
        if (
          error.response &&
          error.response.data.error === "Conflict - duplicate"
        ) {
          console.log("payload.data.answers: ", payload.data.answers);
          console.log("error=>", error.response.data.error);
          router.push("/thuisbatterij-bedankt");
        } else {
          const errorKey = error.response.data.error
            .match(/'([^']+)'/)[0]
            .slice(1, -1);
          commit("setError", { [errorKey]: false });
          console.error("Error Detail:", errorKey);
        }
      }
    }
  },
  // async getAddress({ commit, dispatch }, payload) {
  //   commit("setLeadLoading", true);

  //   const apiKey = "P6JTU52clKYjZca8";

  //   try {
  //     // const apiUrl = `https://api.pro6pp.nl/v2/autocomplete/be?authKey=${apiKey}&postalCode=${payload.zip}&street=${payload.street}`;
  //     const apiUrl = `https://api.pro6pp.nl/v2/autocomplete/nl?authKey=${apiKey}&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}`;

  //     const { data } = await axios.get(apiUrl);
  //     if (data.length > 0) {
  //       let {
  //         street,
  //         streetNumber,
  //         municipality,
  //         postalCode,
  //         premise,
  //         country,
  //         lat,
  //         lng,
  //         region,
  //       } = data[0];

  //       let address =
  //         street +
  //         " " +
  //         streetNumber + "" + premise +
  //         ", " +
  //         municipality +
  //         " " +
  //         (region ? region + "," + " " : "");

  //       commit("setAddress", address);
  //       commit("setLocation", {
  //         lat: lat,
  //         lng: lng,
  //       });

  //       commit("setAddressDetails", data[0]);
  //       payload.onSuccess();
  //     } else {
  //       commit("setAddress",);
  //       commit("setLocation", {
  //         lat: 50.5039,
  //         lng: 4.4699,
  //       });
  //       commit("setLeadLoading", false);
  //       commit("setAddressDetails", {});

  //       payload.onError();
  //     }

  //     commit("setLeadLoading", false);
  //   } catch (error) {
  //     commit("setAddress",);
  //     commit("setLocation", {
  //       lat: 50.5039,
  //       lng: 4.4699,
  //     });
  //     commit("setLeadLoading", false);
  //     commit("setAddressDetails", {});
  //     payload.onError();
  //   }
  // },

  async getStreets({ commit, dispatch }, payload) {
    commit("setLeadLoading", true);

    const apiKey = "P6JTU52clKYjZca8";

    try {
      const apiUrl = `https://api.pro6pp.nl/v2/autocomplete/nl?authKey=${apiKey}&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}`;
      // const apiUrl = `https://api.pro6pp.nl/v2/suggest/be/street?authKey=${apiKey}&postalCode=${payload.zip}&street=${payload.street}`;
      const { data } = await axios.get(apiUrl);
      console.log("data: ", data);

      // commit("setAllStreets", data);
      commit("address", data);
      commit("setLocation", {
        lat: data.lat,
        lng: data.lng,
      });
      payload.onSuccess();

      commit("setLeadLoading", false);
    } catch (error) {
      payload.onError();

      commit("setLeadLoading", false);
      commit("setAddress");
      commit("setLocation", {
        lat: 50.5039,
        lng: 4.4699,
      });
      // commit("setLeadLoading", false);
      // commit("setAllStreets", []);
      commit("address", {});
    }
  },

  async getStreetsByZip({ commit, dispatch }, payload) {
    commit("setLeadLoading", true);

    const apiKey = "P6JTU52clKYjZca8";

    try {
      const apiUrl = `https://api.pro6pp.nl/v2/suggest/nl/street?authKey=${apiKey}&postalCode=${payload.zip}`;
      // const apiUrl = `https://api.pro6pp.nl/v2/suggest/be/street?authKey=${apiKey}&street=${payload.street}`;
      const { data } = await axios.get(apiUrl);
      if (data.length > 0) {
        // Assuming data contains settlement information
        const { settlement } = data[0];

        commit("setAddress", { settlement }); // Store settlement in state
        commit("setLeadLoading", false);
        payload.onSuccess(); // Invoke success callback if needed
      } else {
        // Handle case when no data is returned
        commit("setAddress", { settlement: null }); // Set settlement to null
        commit("setLeadLoading", false);
        payload.onError(); // Invoke error callback if needed
      }
    } catch (error) {
      // console.log("Error", error);
      commit("setAddress");
      commit("setLocation", {
        lat: 50.5039,
        lng: 4.4699,
      });
      commit("setLeadLoading", false);
      commit("setAllStreets", []);
    }
  },
};

const mutations = {
  setLeadLoading(state, payload) {
    state.leadLoading = payload;
  },
  setLeadResponse(state, payload) {
    state.leadResponse = payload;
  },
  setZonnelResponse(state, payload) {
    state.zonnelResponse = payload;
  },
  setAddress(state, payload) {
    state.address = payload;
  },
  setLastAnswers(state, payload) {
    state.lastAnswers = payload;
  },
  setLocation(state, payload) {
    state.location = payload;
  },
  setAddressDetails(state, payload) {
    state.addressDetails = payload;
  },
  setAllStreets(state, payload) {
    state.allStreets = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
  address(state, payload) {
    state.address = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
