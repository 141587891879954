<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          <!-- Heb je een plat of een schuin dak? -->
          Wanneer is je woning gebouwd?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <!-- <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item" @click="handleClick('3884')">
                  <b-form-radio value="3884">Schuin dak </b-form-radio>
                </div>

                <div class="check-item mt-3" @click="handleClick('3881')">
                  <b-form-radio value="3881">Plat dak</b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group> -->
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item mt-3" @click="handleClick('4214')">
                  <b-form-radio value="4214">Bouwjaar vóór 1920</b-form-radio>
                </div>
                <div class="check-item mt-3" @click="handleClick('4217')">
                  <b-form-radio value="4217"
                    >Bouwjaar tussen 1920 en 1976</b-form-radio
                  >
                </div>
                <div class="check-item mt-3" @click="handleClick('4220')">
                  <b-form-radio value="4220"
                    >Bouwjaar tussen 1977 en 1984</b-form-radio
                  >
                </div>
                <div class="check-item mt-3" @click="handleClick('4223')">
                  <b-form-radio value="4223"
                    >Bouwjaar tussen 1985 en 1991</b-form-radio
                  >
                </div>
                <div class="check-item mt-3" @click="handleClick('4226')">
                  <b-form-radio value="4226">Bouwjaar ná 1991</b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "Fourth_Question",
  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      this.$emit("nextQuestion", "5", val);
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "3");
    },
  },
  watch: {
    selected() {
      this.$emit("nextQuestion", "5", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
</style>
