import { render, staticRenderFns } from "./SecondCQuestion.vue?vue&type=template&id=5e24f5f6&scoped=true"
import script from "./SecondCQuestion.vue?vue&type=script&lang=js"
export * from "./SecondCQuestion.vue?vue&type=script&lang=js"
import style0 from "./SecondCQuestion.vue?vue&type=style&index=0&id=5e24f5f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e24f5f6",
  null
  
)

export default component.exports