import { render, staticRenderFns } from "./ThirdQuestion.vue?vue&type=template&id=65e3c5a1&scoped=true"
import script from "./ThirdQuestion.vue?vue&type=script&lang=js"
export * from "./ThirdQuestion.vue?vue&type=script&lang=js"
import style0 from "./ThirdQuestion.vue?vue&type=style&index=0&id=65e3c5a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e3c5a1",
  null
  
)

export default component.exports