<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Heb je ook interesse in zonnepanelen ?*
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item mt-3" @click="handleClick('4573')">
                  <b-form-radio value="4573">Ja</b-form-radio>
                </div>
                <div class="check-item mt-3" @click="handleClick('4572')">
                  <b-form-radio value="4217">Nee</b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <p>
          <span class="font-weight-bold"> Tip! </span> Met zonnepanelen kun jij
          jouw auto
          <span class="font-weight-bold">vrijwel gratis opladen.</span> Dubbel
          voordeel.
        </p>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";
// import FifthAQuestion from "./FifthA_Question.vue";
// import FifthBQuestion from "./FifthB_Question.vue";
// import FifthCQuestion from "./FifthC_Question.vue";
// import FifthDQuestion from "./FifthD_Question.vue";
// import FifthEQuestion from "./FifthE_Question.vue";

export default {
  name: "Fifth_Question",
  components: {
    // FifthAQuestion,
    // FifthBQuestion,
    // FifthCQuestion,
    // FifthDQuestion,
    // FifthEQuestion,
    GoogleMapSection,
  },
  props: {
    question: {
      type: String,
      required: true,
    },
    ans: {
      type: String,
    },
    max: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      value: "",
      houseRegex: /^[1-9]+$/,
    };
  },

  methods: {
    handleClick(val) {
      // console.log("val:<<>> ", val);
      // if (val === "4573") {
      this.$emit("nextQuestion", "analyze", val);
      // } else {
      //   this.$emit("handleClick", val);
      //   this.$emit("nextQuestion", "4", val);
      // }
    },
    handleSelected(arr) {
      this.$emit("handleNextSelected", arr);
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "4");
    },
  },

  computed: {
    houseNumberValidation() {
      return this.houseRegex.test(this.value) || this.value === "";
    },
    isValidForm() {
      return this.houseNumberValidation;
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../../assets/imgs/secondQuestion.png);
    background-size: 100% 100% !important;
  }
}
.info {
  &__img {
    height: 20px;
    width: 20px;
  }
}
</style>
